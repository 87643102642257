.custom-form-control-input {
  width: 100%;
  display: flex;
  align-items: center;
  .kite-form-control__input {
    max-width: 100% !important;
  }
  label {
    text-transform: capitalize;
    color: var(--kite-color-gray-30);
    white-space: nowrap;
    &.show-max-length{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  .input {
    width: 100%;
  }
  textarea {
    min-width: 100%;
  }
  &.left {
    label {
      padding-right: var(--kite-size-lg);
    }
  }
  &.top {
    flex-direction: column;
    align-items: flex-start;
    label {
      padding-bottom: var(--kite-size-xs);
    }
  }
  &.sm {
    --kite-text-input-height: 32px;
    input {
      padding: 0.5rem 0.25rem !important;
    }
  }
}
