.page-container {
  padding: var(--kite-size-lg);
}
.table-filters {
  display: flex;
  align-items: center;
  .filters-container {
    flex-grow: 1;
  }
  .table-view-type {
    margin-left: var(--kite-size-sm);
  }
}
.alert-element {
  margin: var(--kite-size-md) 0;
}
// Custom Dialog styles
.custom-dialog {
  overflow: auto;
  .kite-dialog__content {
    padding-bottom: 0;
  }
  .kite-button-group {
    display: none;
  }
  .dialog-actions {
    margin: var(--kite-button-group-margin-top) 0;
  }
}
// Confirm Dialog Styles
.confirm-dialog {
  .kite-dialog__dismiss-button {
    display: none;
  }
}

.flex-center {
  display: flex;
  align-items: center;
}
.copy-icon {
  margin-right: var(--kite-size-sm);
}
.cell-link {
  color: var(--kite-color-black);
}
.cell-file-path {
  color: var(--kite-color-gray-30);
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer !important;
    color: var(--kite-color-blue-10);
  }
  &::before {
    content: none !important;
  }
  //&:has(.cell-icon:hover) {
  //  color: var(--kite-color-blue-10);
  //}
  .cell-icon {
    margin-left: var(--kite-spacing-element-sm);
    cursor: pointer;
  }
}
.cell-link {
  .link-text {
    color: var(--kite-color-blue-10);
    text-decoration: underline;
    font-weight: 500;
    &:hover {
      cursor: pointer;
    }
  }
}
// Edit page styles
.setup-container {
  border: 1px solid var(--kite-border-color);
  border-radius: var(--kite-border-radius-md);
  position: relative;
}
.setup-content-container {
  width: 50%;
  max-width: 700px;
  margin: var(--kite-size-lg) auto;
  .field {
    margin-bottom: var(--kite-size-md);
  }
  .section {
    padding: var(--kite-size-lg) 0;
    border-bottom: 1px solid var(--kite-border-color);
  }
  .side-heading {
    padding: var(--kite-size-xs) 0;
    font-weight: var(--kite-font-weight-medium);
    font-size: 20px;
    line-height: 26px;
  }
}
.setup-actions {
  width: 200px;
  margin-top: var(--kite-size-lg);
}
.display-inline {
  display: inline-flex;
}
