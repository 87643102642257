.custom-form-control-select {
  width: 100%;
  display: flex;
  align-items: center;
  .MuiFormHelperText-root {
    color: var(--kite-form-control-input-error-border-color);
    display: block;
    font-size: var(--kite-form-control-error-text-size);
    margin-top: var(--kite-form-control-error-text-margin-top);
    margin-left: 0;
    font-family: inherit;
  }
  .MuiInputBase-root {
    fieldset {
      border-color: var(--kite-form-control-input-border-color);
    }
  }
  &:hover {
    fieldset {
      border-width: var(--kite-form-control-hover-border-width);
    }
  }
  .MuiInputBase-root {
    width: 100%;
    font-family: inherit;
    border-radius: 0;
  }
  label {
    text-transform: capitalize;
    color: var(--kite-color-gray-30);
    white-space: nowrap;
  }
  select {
    padding-right: 2.2rem !important;
    text-overflow: ellipsis;
    max-width: 100% !important;
  }
  &.left {
    label {
      padding-right: var(--kite-size-lg);
    }
  }
  &.top {
    flex-direction: column;
    align-items: flex-start;
    label {
      padding-bottom: var(--kite-size-xs);
    }
  }
  &.small {
    .MuiOutlinedInput-input {
      padding: 0.3rem 2.2rem 0.3rem 0.5rem !important;
    }
    .kite-multi-select__icon {
      position: relative;
      right: 16px;
      .kite-icon__inner svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}
