.custom-form-control-button {
  &.sm {
    height: 20px;
  }
  &.md {
    height: 32px;
  }
  &.rounded {
    border-radius: 40px;
  }
  &.iconOnly {
    border: none;
  }
  &.danger {
    background-color: var(--kite-color-error);
    &:hover{
      background-color: var(--kite-color-error-dark);
    }
  }
}
