header {
  background-color: var(--kite-color-dark-blue-20);
  height: 75px;
  display: flex;
  align-items: center;
  padding: var(--kite-size-md) var(--kite-size-lg);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}
.logo-container {
  display: flex;
  align-items: center;
  .msm-logo {
    margin-left: var(--kite-size-lg);
  }
}
.links-container {
  flex-grow: 1;
  text-align: center;
  .link {
    display: inline-block;
    text-align: center;
    line-height: 4.6rem;
    height: 4.6rem;
    text-decoration: none;
    margin: 0 var(--kite-size-xl);
    color: var(--kite-color-white);
    outline: none;
    font-size: var(--kite-typography-sm-eyebrow-size);
    &:hover {
      cursor: pointer;
      border-bottom: 4px solid var(--kite-color-blue-20);
    }
    &.link-active {
      border-bottom: 4px solid var(--kite-color-blue-20);
    }
    //customising kite library menu trigger styles
    &.dropdown-link {
      .kite-menu__trigger {
        .kite-icon {
          display: none;
        }
      }
      .kite-menu__trigger-label {
        color: var(--kite-color-white);
      }
      .kite-menu--contextual-label .kite-menu__trigger {
        padding: 0;
        height: 4.5rem;
      }
      .kite-menu__trigger--hover,
      .kite-menu__trigger:hover {
        background-color: transparent;
      }
      .kite-menu--open .kite-menu__trigger {
        background-color: transparent;
      }
      .kite-menu__action:disabled {
        font-weight: var(--kite-font-weight-bold);
        color: var(--list-item-text-color);
        cursor: pointer;
      }
    }
  }
}
//customising kite library menu trigger styles
.user-menu {
  .kite-menu--block .kite-menu__list {
    left: -100px;
    min-width: 150px;
  }
  .kite-menu__trigger {
    background: var(--kite-color-primary);
    border-radius: var(--kite-border-radius-md);
    color: var(--kite-color-white);
    cursor: var(--kite-cursor-action);
    height: 2.5rem;
    .kite-icon {
      display: none;
    }
  }
  .kite-menu__trigger-label {
    color: var(--kite-color-white);
  }
  .kite-menu__trigger--hover,
  .kite-menu__trigger:hover {
    background: var(--kite-color-blue-30);
  }
  .kite-menu__action:disabled {
    font-weight: var(--kite-font-weight-bold);
    color: var(--list-item-text-color);
    cursor: pointer;
  }
}
