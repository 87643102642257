.custom-auto-complete {
  .MuiChip-root {
    font-family: inherit;
    background: #d8dde6;
    border: none;
  }
  .MuiOutlinedInput-root {
    border-radius: 0;
    font-family: inherit;
    &.Mui-focused {
      fieldset {
        border: var(--kite-form-control-input-focus-ring-width)
          var(--kite-form-control-input-focus-ring-style)
          var(--kite-form-control-input-focus-ring-color);
      }
    }
  }
}
.custom-form-control-input {
  width: 100%;
  display: flex;
  align-items: center;
  .custom-auto-complete {
    width: 100%;
    .MuiOutlinedInput-root {
      &:not(.Mui-error) {
        fieldset {
          border-color: var(--kite-color-gray-30);
        }
      }
      &:hover {
        fieldset {
          border-width: 2px;
        }
      }
    }
  }
  label {
    text-transform: capitalize;
    color: var(--kite-color-gray-30);
    white-space: nowrap;
  }
  &.left {
    label {
      padding-right: var(--kite-size-lg);
    }
  }
  &.top {
    flex-direction: column;
    align-items: flex-start;
    label {
      padding-bottom: var(--kite-size-xs);
    }
  }
}
