.filters {
  display: flex;
  align-items: center;
  background: var(--kite-color-background);
  border: 1px solid var(--kite-border-color);
  border-radius: 4px;
  padding: 8px 16px;
  .filter-fields {
    display: flex;
    flex-grow: 1;
  }
  .actions {
    display: flex;
    align-items: center;
    margin-left: var(--kite-size-sm);
    .action {
      margin: 0 var(--kite-size-xxs);
    }
  }
}
.filters-title {
  margin: 0;
  padding-right: var(--kite-size-sm);
  border-right: 2px solid var(--kite-border-color);
  display: flex;
  align-items: center;
  .filter-icon {
    margin-right: var(--kite-size-sm);
  }
}
.filter-field {
  display: flex;
  align-items: center;
  width: 50%;
  margin: 0 var(--kite-size-sm);
}
//More Filters
.more-filters-popover {
  h6 {
    margin-bottom: 5px;
  }
  .footer-actions {
    margin-top: var(--kite-size-md);
    display: flex;
    .action {
      margin-right: var(--kite-size-xxs);
    }
  }
  .MuiPaper-root {
    width: 50%;
    max-width: 720px;
    box-shadow: var(--kite-elevation-1-dark);
    border: 1px solid var(--kite-border-color);
    border-radius: 4px;
    padding: var(--kite-size-lg);
    overflow: visible;
  }
  &.large {
    .MuiPaper-root {
      width: 70%;
      max-width: 1220px;
    }
  }
}
