.alert-element {
  margin: var(--kite-size-md) 0;
}
.cell-status {
  color: var(--kite-color-white);
  border-radius: var(--kite-size-xl);
  text-align: center;
  box-shadow: var(--kite-elevation-1);
  display: inline-block;
  padding: var(--kite-size-xxs) var(--kite-size-sm);
  font-weight: var(--kite-font-weight-regular);
  background-color: var(--kite-color-gray-30);
  width: 100px;
  letter-spacing: 0.02rem;
  &.Resolved {
    background-color: var(--kite-color-positive);
  }
  &.Active {
    background-color: var(--kite-color-negative);
  }
  &.Completed {
    background-color: var(--kite-color-positive);
  }
  &.Processing {
    background-color: var(--kite-color-caution);
  }
  &.Incomplete {
    background-color: var(--kite-color-negative);
  }
}

.import-cell-header {
  border-top: 3px solid var(--kite-color-light-blue-20) !important;
}
.export-cell-header {
  border-top: 3px solid var(--kite-color-green-10) !important;
}

.import-cell,
.export-cell {
  .import-data-cell,
  .export-data-cell {
    display: flex;
    align-items: center;
    .date {
      display: flex;
      align-items: center;
      width: 255px;
      .cell-icon {
        margin-right: var(--kite-size-xs);
      }
    }
    .time {
      margin-left: var(--kite-size-xs);
    }
    .separator {
      color: var(--kite-border-color);
      margin: 0 var(--kite-size-xs);
    }
    &.default {
      flex-direction: column;
      align-items: flex-start;
      line-height: var(--kite-size-xl);
      .separator {
        display: none;
      }
    }
    .count {
      width: 90px;
      .primary-count {
        font-weight: var(--kite-font-weight-bold);
        color: var(--kite-color-primary);
      }
    }
    .cell-error {
      display: flex;
      align-items: center;
      color: var(--kite-color-negative);
      margin-left: var(--kite-size-xxs);
      .kite-tooltip {
        cursor: pointer;
        color: var(--kite-color-negative);
        margin-left: var(--kite-size-xxs);
        z-index: 1;
      }
    }
  }
}
tr:not(:hover) {
  .import-cell,
  .export-cell {
    background: var(--kite-color-background);
  }
}
