.MuiBreadcrumbs-ol {
  .MuiBreadcrumbs-li .MuiTypography-root {
    font-family: var(--kite-font-family-sans-serif);
    font-size: calc(var(--kite-typography-sm-caption-size) * 0.95);
    cursor: pointer;
  }
  .MuiBreadcrumbs-li:last-of-type .MuiTypography-root {
    font-weight: var(--kite-font-weight-bold);
    cursor: auto;
  }
}
