.grid-container {
  padding: var(--kite-size-lg);
  .each-row {
    margin: var(--kite-size-xs) 0;
    &:first-of-type {
      .value {
        min-height: 60px;
      }
    }
  }
  .field {
    margin: 0 var(--kite-size-xs);
    border-bottom: 1px solid var(--kite-border-color);
    .label {
      font-size: var(--kite-typography-lg-caption-size);
      font-weight: var(--kite-font-weight-medium);
      color: var(--kite-color-gray-30);
      margin-bottom: var(--kite-size-xs);
    }
    .value {
      font-size: var(--kite-typography-lg-body-size);
      min-height: 30px;
    }
  }
}
.remarks-container {
  margin: var(--kite-size-md) 0;
  .label {
    font-size: var(--kite-typography-lg-caption-size);
    font-weight: var(--kite-font-weight-medium);
    color: var(--kite-color-gray-30);
    margin-bottom: var(--kite-size-xs);
  }
  .grey-container {
    background-color: var(--kite-color-gray-10);
    color: var(--kite-color-gray-30);
    max-height: 200px;
    overflow: auto;
    padding: var(--kite-size-lg);
    font-size: var(--kite-typography-lg-caption-size);
    font-weight: var(--kite-font-weight-medium);
    border: 1px solid var(--kite-border-color);
  }
}
.secondary-container {
  padding: var(--kite-size-lg);
  margin-top: var(--kite-size-lg);
}
.import-export-status {
  color: var(--kite-color-white);
  border-radius: var(--kite-size-xl);
  text-align: center;
  box-shadow: var(--kite-elevation-1);
  display: inline-block;
  padding: var(--kite-size-xxs) var(--kite-size-sm);
  font-weight: var(--kite-font-weight-regular);
  background-color: var(--kite-color-gray-30);
  letter-spacing: 0.02rem;
  &.Completed {
    background-color: var(--kite-color-positive);
  }
  &.Incomplete {
    background-color: var(--kite-color-negative);
  }
}
