.header {
  display: flex;
  justify-content: space-between;
  h2 {
    padding: var(--kite-size-xs) 0;
  }
}
.actions {
  display: flex;
  align-items: center;
  .action {
    margin-right: var(--kite-size-sm);
    &:last-of-type {
      margin: 0;
    }
  }
}
