@import '~@kite/react-kite/dist/react-kite.css';
@import 'styles/index';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: auto !important;
}
// Global material ui styles override to align with kite design
.Mui-checked {
  color: var(--kite-checkbox-selected-background-color) !important;
}
.MuiButtonBase-root .MuiCheckbox-root {
  color: var(--kite-checkbox-border-color);
}
.MuiButtonBase-root {
  &.MuiMenuItem-root {
    font-family: inherit;
  }
}
// Global kite styles override
abbr.kite-tooltip__trigger,
button.kite-tooltip__trigger {
  z-index: 1;
}
.kite-checkbox {
  margin: 0;
}
.kite-form-control__error {
  color: var(--kite-form-control-error-text-color);
  display: block;
  font-size: var(--kite-form-control-error-text-size);
  margin-top: var(--kite-form-control-error-text-margin-top);
}
.details-dialog {
  padding: var(--kite-size-xxl);
  padding-top: var(--kite-size-md);
  max-width: 900px;
  form{
    width: 100%;
  }
  .kite-dialog__container{
    padding: 2rem;
  }
  .kite-button-group {
    display: none;
  }
  .kite-dialog__content {
    border-top: none;
    padding: 0 2rem 2rem 2rem;
    overflow: hidden;
  }
  .modal-content-container {
    overflow: auto;
    height: calc(100vh - 340px);
  }
}
