.help-text {
  font-size: 10px;
  text-transform: lowercase;
}
.each-field {
  margin: var(--kite-size-md) 0;
  display: flex;
  align-items: center;
}
.clear-icon {
  margin: 0 0.5rem;
  cursor: pointer;
  color: var(--kite-color-primary);
  &:hover {
    color: var(--kite-color-primary-dark);
  }
}
.action-btns {
  margin-top: var(--kite-size-sm);
  display: inline-block;
}
.each-attachment-container {
  width: 100%;
  position: relative;
  &.existing {
    pointer-events: none;
    .file-input-container {
      background-color: var(--kite-color-gray-10);
    }
  }
}
.file-status-icon {
  position: absolute;
  top: 6px;
  right: 10px;
  .success-icon {
    color: var(--kite-color-success);
  }
  .error-icon {
    color: var(--kite-color-negative);
  }
}
.progress-container {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 1;
  opacity: 0.8;
}
