.auth-page-container {
  background-image: url('../../assets/images/login-hero-image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 155px);
}
//amplify style customisations
.amplify-tabs {
  display: none;
}
[data-amplify-authenticator-forcenewpassword] {
  h3 {
    font-size: var(--kite-typography-lg-title-4-size);
    font-weight: var(--kite-typography-lg-title-4-weight);
    margin: var(--kite-spacing-element-lg);
    text-align: center;
  }

  .amplify-button--primary {
    margin: var(--kite-spacing-element-xxl) 0;
  }
}
[data-amplify-authenticator] [data-amplify-router] {
  border-color: var(--kite-border-color);
}
[data-amplify-authenticator] [data-amplify-form] {
  padding: 0 var(--amplify-components-authenticator-form-padding);
}
[data-amplify-authenticator] {
  margin-top: var(--kite-spacing-element-xl);
  margin-right: var(--kite-spacing-element-xl);
}
.auth-header {
  font-size: var(--kite-typography-lg-title-4-size);
  font-weight: var(--kite-typography-lg-title-4-weight);
  margin: var(--kite-spacing-element-lg);
  text-align: center;
}
.auth-footer {
  display: flex;
  padding: var(--kite-spacing-element-md);
}
.amplify-input {
  background-color: var(--kite-form-control-input-background-color);
  border: var(--kite-form-control-input-border-width) var(--kite-form-control-input-border-style)
    var(--kite-form-control-input-border-color);
  border-radius: 0;
  color: var(--kite-form-control-input-text-color);
  outline: none;
  transition: none;
  &:focus {
    box-shadow: none;
  }
  &.focus-visible {
    outline: none;
    border: var(--kite-form-control-input-focus-ring-width)
      var(--kite-form-control-input-border-style) var(--kite-form-control-input-focus-ring-color);
  }
}
.amplify-button {
  border-radius: 0;
}
.amplify-button--primary {
  &:not(.amplify-button--loading) {
    background-color: var(--kite-button-primary-background-color);
    border-radius: var(--kite-button-border-radius);
    &:hover {
      background-color: var(--kite-button-primary-hover-background-color);
    }
  }
}
//reset password
.amplify-button--link {
  display: none;
}
//auth specific
.auth-container {
  display: flex;
  justify-content: flex-end;
  padding: var(--kite-spacing-element-xxl);
  .amplify-button--primary,
  .amplify-button--loading,
  .amplify-button--disabled {
    margin-top: var(--kite-spacing-element-lg);
  }
}
