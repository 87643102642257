.section-heading {
  font-size: var(--kite-typography-lg-eyebrow-size);
  font-weight: var(--kite-font-weight-bold);
  color: var(--kite-color-gray-30);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: var(--kite-size-lg);
}
.section-container {
  padding: var(--kite-size-lg);
}
.gray-container {
  background: var(--kite-color-background);
  margin-top: var(--kite-size-sm);
}
.text-link {
  display: flex;
  align-items: center;
  .kite-icon {
    margin-right: var(--kite-size-sm);
  }
}
.help-link-section {
  margin: var(--kite-size-xxl) 0;
  .help-link {
    margin-bottom: var(--kite-size-md);
  }
}
.button-link {
  background-color: var(--kite-color-primary);
  padding: var(--kite-size-xs);
  display: flex;
  align-items: center;
  color: var(--kite-color-white);
  border-radius: 4px;
  .kite-icon {
    margin-left: var(--kite-size-sm);
  }
  .kite-link {
    color: var(--kite-color-white);
  }
}
