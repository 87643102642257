.custom-form-control-input {
  width: 100%;
  display: flex;
  align-items: center;
  .auto-complete-input {
    //width: 100%;
    //background-color: var(--kite-color-white);
    //height: 32px;
    //display: flex;
    //align-items: center;
    //border: var(--kite-form-control-input-border-width) var(--kite-form-control-input-border-style)
    //  var(--kite-form-control-input-border-color);
    //border-radius: 0;
    //padding: 6px;
    //&:hover {
    //  border-width: 2px;
    //}
    width: 100%;
    background-color: var(--kite-color-white);
  }
  .kite-form-control__input {
    max-width: 100% !important;
  }
  label {
    text-transform: capitalize;
    color: var(--kite-color-gray-30);
    white-space: nowrap;
  }
  .input {
    width: 100%;
  }
  &.left {
    label {
      padding-right: var(--kite-size-lg);
    }
  }
  &.top {
    flex-direction: column;
    align-items: flex-start;
    label {
      padding-bottom: var(--kite-size-xs);
    }
  }
  &.sm {
    --kite-text-input-height: 32px;
    input {
      padding: 0.5rem 0.25rem !important;
    }
  }
}
.custom-select-popover {
  .footer-actions {
    margin-top: var(--kite-size-md);
    display: flex;
    .action {
      margin-right: var(--kite-size-xxs);
    }
  }
  .MuiPaper-root {
    width: 50%;
    max-width: 720px;
    box-shadow: var(--kite-elevation-1-dark);
    border: 1px solid var(--kite-border-color);
    border-radius: 4px;
    padding: var(--kite-size-lg);
    overflow: visible;
  }
  &.large {
    .MuiPaper-root {
      width: 70%;
      max-width: 1220px;
    }
  }
  .select-title {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  .select-input-help {
    font-size: 0.8rem;
    margin: var(--kite-size-xs) 0;
  }
}
