.custom-form-control-select {
  width: 100%;
  display: flex;
  align-items: center;
  label {
    text-transform: capitalize;
    color: var(--kite-color-gray-30);
    white-space: nowrap;
  }
  select {
    padding-right: 2.2rem !important;
    text-overflow: ellipsis;
    max-width: 100% !important;
    option:first-of-type{
      color: var(--kite-color-gray-30);
    }
  }
  &.left {
    label {
      padding-right: var(--kite-size-lg);
    }
  }
  &.top {
    flex-direction: column;
    align-items: flex-start;
    label {
      padding-bottom: var(--kite-size-xs);
    }
  }
  &.sm {
    --kite-select-height: 32px;
    select {
      padding: 0.3rem 2.2rem 0.3rem 0.5rem !important;
    }
    .kite-select__icon {
      width: 20px !important;
      height: 20px !important;
    }
  }
  .kite-form-control {
    width: 100%;
  }
}
