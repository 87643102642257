.custom-form-control-input {
  width: 100%;
  display: flex;
  align-items: center;
  label {
    text-transform: capitalize;
    color: var(--kite-color-gray-30);
    white-space: nowrap;
  }
  &.error-control {
    .file-input-container {
      border-color: var(--kite-color-error);
    }
  }
  .file-input-container {
    background-color: var(--kite-form-control-input-background-color);
    border: var(--kite-form-control-input-border-width) var(--kite-form-control-input-border-style)
      var(--kite-form-control-input-border-color);
    border-radius: 0;
    width: 100%;
    position: relative;
    color: #63738a;
    display: flex;
    align-items: center;
    cursor: pointer;
    .file-placeholder {
      display: flex;
      align-items: center;
      &.filled {
        color: var(--kite-color-black);
      }
    }
    .file-icon {
      margin-right: var(--kite-size-xs);
    }
    input {
      position: absolute;
      width: 100%;
      opacity: 0;
    }
    &:hover {
      border-width: 2px;
    }
  }
  &.left {
    label {
      padding-right: var(--kite-size-lg);
    }
  }
  &.top {
    flex-direction: column;
    align-items: flex-start;
    label {
      padding-bottom: var(--kite-size-xs);
    }
  }
  &.sm {
    .file-input-container {
      padding: 0.5rem 0.25rem !important;
      height: 32px;
    }
  }
}
.kite-form-control__error {
  &.sm {
    font-size: 12px;
  }
}
