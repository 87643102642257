footer{
  background-color: var(--kite-color-dark-blue-20);
  height: 80px;
  display: flex;
  align-items: center;
  padding: var(--kite-size-md) var(--kite-size-lg);
  justify-content: space-between;
  font-size: 12px;
}
.links-container{
  .external-link{
    margin: 0 var(--kite-spacing-element-sm);
    color: var(--kite-color-white);
  }
}
.copy-right{
  color: var(--kite-color-gray-25);
  margin-top: var(--kite-spacing-element-sm);
}
