.page-container{
  margin: 0 var(--kite-size-lg);
}
.details-container {
  margin: var(--kite-size-lg);
}
.details-label{
  color: var(--kite-color-gray-30);
  letter-spacing: 1px;
  font-size: var(--kite-typography-sm-eyebrow-size);
  margin-bottom: var(--kite-size-xs);
}
.details-path{
  letter-spacing: 1px;
  color: var(--kite-color-gray-25);
  margin-top: var(--kite-size-xxs);
  font-size: var(--kite-typography-sm-eyebrow-size);
}
.cell-icon{
  margin-left: var(--kite-size-xs);
  cursor: pointer;
}
.associated-table-container{
  margin: var(--kite-size-lg) 0;
}
