.upload-container {
  background: var(--kite-color-background);
  border: 1px dashed var(--kite-form-control-input-border-color);
  border-radius: 4px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  padding: var(--kite-size-sm);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .upload-icon {
    margin-right: var(--kite-size-xxs);
  }
  .file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
.field {
  margin: var(--kite-size-sm) 0;
}
