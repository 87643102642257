.field-row {
  border-bottom: 2px solid var(--kite-border-color);
  padding: var(--kite-size-sm) 0;
  margin: 0 !important;
  &.no-divider {
    border: none;
  }
}
.row-field {
  display: flex;
  &.block {
    display: block;
    width: 100%;
  }
  label {
    color: var(--kite-color-gray-30);
    font-weight: 500;
    margin-right: var(--kite-size-sm);
  }
  .value-bold {
    font-weight: 500;
  }
}
.related-links-container {
  display: flex;
  flex-direction: column;
  .related-link {
    color: var(--kite-color-primary);
    padding: var(--kite-size-xxs) 0;
    display: flex;
    align-items: center;
    a {
      color: var(--kite-color-primary);
      word-break: break-all;
      font-size: 13px;
    }
    .link-icon {
      margin-left: var(--kite-size-xxs);
    }
    &:hover {
      color: var(--kite-color-blue-10);
      a {
        color: var(--kite-color-blue-10);
      }
    }
  }
}
.attachments-container {
  display: flex;
  flex-direction: column;
  .attachment-link {
    display: flex;
    align-items: center;
    padding: var(--kite-size-xxs) var(--kite-size-sm);
    margin: var(--kite-size-xxs) 0;
    border: 1px solid var(--kite-border-color);
    border-radius: 4px;
    background-color: var(--kite-color-background);
    font-size: 14px;
    color: var(--kite-color-gray-30);
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: var(--kite-color-blue-10);
    }
    .link-icon {
      margin-right: var(--kite-size-xxs);
    }
  }
}
.notes-container {
  border: 1px solid var(--kite-border-color);
  padding: var(--kite-size-sm);
  color: var(--kite-color-gray-25);
  background-color: var(--kite-color-background);
  font-size: 14px;
  border-radius: 4px;
}
.modal-actions {
  margin-top: var(--kite-size-md);
  display: flex;
  width: 300px;
  .primary-btn {
    margin-right: var(--kite-size-sm);
  }
}
