.table-wrapper {
  position: relative;
  .kite-progress {
    z-index: 3;
  }
  .kite-data-table {
    min-height: 300px;
  }
}
.table-container {
  overflow: auto;
  margin-top: var(--kite-size-lg);
}
.kite-data-table table {
  font-size: var(--kite-typography-lg-caption-size);
  border-collapse: separate !important;
  border-spacing: 0;
}
.kite-data-table table tr th,
.kite-data-table table tbody tr td {
  &.fixed {
    position: sticky;
    z-index: 2;
    margin: 0;
    background-color: #fff;
    &:has(.kite-menu__list) {
      z-index: 3;
    }
    &.left {
      left: 0;
      box-shadow: 3px 0 4px var(--kite-border-color);
      background-color: var(--kite-color-background);
    }
    &.right {
      right: 0;
      box-shadow: -3px 0 4px var(--kite-border-color);
      .kite-data-table__header-action {
        visibility: hidden;
      }
      .kite-menu__trigger {
        --kite-menu-trigger-padding: 4px;
      }
    }
  }
}

.kiterowdetail{  
  background-color: var(--kite-color-white) !important; 
}

.textnobreak {
  white-space: nowrap;
}
