.custom-form-control-date {
  width: 100%;
  display: flex;
  align-items: center;
  label {
    text-transform: capitalize;
    color: var(--kite-color-gray-30);
    white-space: nowrap;
  }
  .react-datepicker {
    box-shadow: var(--kite-elevation-1-dark);
    border: none;
    font-family: inherit;
    padding: var(--kite-size-xs);
    padding-bottom: var(--kite-size-xxl);
    padding-top: 0;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
    margin: 2px 0;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0;
    background: var(--kite-color-primary);
  }
  .react-datepicker__day--disabled {
    background-color: transparent;
  }
  .react-datepicker__day--keyboard-selected {
    color: inherit;
    background-color: inherit;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  //.react-datepicker__week {
  //  .react-datepicker__day--in-selecting-range {
  //    &.react-datepicker__day--selecting-range-start {
  //      border-radius: 50% 0 0 50%;
  //    }
  //  }
  //  .react-datepicker__day--in-range {
  //    &.react-datepicker__day--weekend {
  //      &:first-child {
  //        border-radius: 50% 0 0 50%;
  //      }
  //      &:last-child {
  //        border-radius: 0 50% 50% 0;
  //      }
  //    }
  //  }
  //  .react-datepicker__day--in-selecting-range {
  //    &.react-datepicker__day--weekend {
  //      &:first-child {
  //        border-radius: 50% 0 0 50%;
  //      }
  //      &:last-child {
  //        border-radius: 0 50% 50% 0;
  //      }
  //    }
  //  }
  .react-datepicker__day--in-range {
    &.react-datepicker__day--range-start {
      background-color: var(--kite-color-dark-blue-10);
      //border-radius: 50% 0 0 50%;
    }
  }
  .react-datepicker__day--in-range {
    &.react-datepicker__day--range-end {
      background-color: var(--kite-color-dark-blue-10);
      //border-radius: 0 50% 50% 0;
    }
  }
  //}
  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
  }
  .react-datepicker__navigation--previous {
    left: 10px;
  }
  .react-datepicker__navigation {
    top: 30px;
  }
  .react-datepicker__day-names {
    font-weight: var(--kite-font-weight-bold);
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin: var(--kite-size-sm) 0;
  }
  .react-datepicker-time__input{
    min-width: 150px;
  }
  input {
    width: 100%;
    border: none;
    outline: 1px solid var(--kite-form-control-input-border-color);
    &:hover {
      outline-width: var(--kite-form-control-hover-border-width);
    }
    &:focus:not(.focus-visible) {
      outline: var(--kite-form-control-hover-border-width) solid var(--kite-focus-ring-color);
      outline-offset: 0;
    }
  }
  &.left {
    label {
      padding-right: var(--kite-size-lg);
    }
  }
  &.top {
    flex-direction: column;
    align-items: flex-start;
    label {
      padding-bottom: var(--kite-size-xs);
    }
  }
  &.sm {
    input {
      padding: 0.5rem 0.25rem !important;
      height: 30px;
    }
  }
  &.lg {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 3rem;
      line-height: 2.2rem;
      margin: 2px 0;
    }
  }
  &.error-control {
    input {
      outline: 1px solid var(--kite-form-control-input-error-border-color);
    }
    .kite-form-control__error {
      color: var(--kite-form-control-error-text-color);
      display: block;
      font-size: var(--kite-form-control-error-text-size);
      margin-top: var(--kite-form-control-error-text-margin-top);
    }
  }
}
